
:root {
  --maincolor1: #f0b90b;
  --maincolor2: #8db8fc;
  --maincolor: #0b4182;
  --maincolor4: #ff0000;
}
.App {
  text-align: center;
  padding: 0px;

}
.font50{
  font-size: 50px !important;
}

.networkColor{
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.bsc{
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(240,185,11, 0.3));
  
}

.polygon{
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(144, 49, 212, 0.3));
}


.textLeft{
  text-align: left;
}
.loansSubtitle{
  color: var(--maincolor);
  font-size: 15px;
  font-weight: 700;
 
  margin-top:-30px;
  margin-bottom:15px;
  display: block;
}

.rewardIcon{
  color: var(--maincolor);
  font-size: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input{
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  appearance: none;
  background-color: #ffffff;
  width: 100%;
  margin-top: 0.25rem;
  text-align-last: center;
  text-align: center;
}

.card{
  /*box-shadow:0 4px 24px 0 rgba(0,0,0,.01);*/
  box-shadow: none;
  border:0px !important;
  border-radius: 15px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  background: linear-gradient(to left,rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6));
}

.card-header{
  background: transparent;
  border-bottom: 0px;
}

.progress{
  --bs-progress-bar-bg:var(--maincolor);
}

.stakesBox{
  max-height: 500px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 20px;;
  box-shadow:0 4px 24px 0 rgba(0,0,0,.08);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #333333;
}

.small-text{
  font-size: 15px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.large-button-blue-disabled {
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  
  color: #a7a7a7 !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #c7c7c7!important;
  border-radius: 30px !important;

  cursor: not-allowed !important;
  transition: background-color 0.2s !important;
  width:100% !important;
}

.large-button-blue {
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  
  background-color: var(--maincolor) !important;
  color: #ffffff !important;
  border: 1px solid var(--maincolor) !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  transition: background-color 0.2s !important;
  width:100% !important;
  transition: all 0.3s !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 3px 8px 0px !important;
}

.large-button-blue:hover {
  background-color: var(--maincolor) !important;
  color:#FFFFFF !important;
  transition: all 0.5s !important;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 3px 8px 0px !important;
  
}

input{
  border: 0px !important;
  padding: 0.75rem 1.5rem !important;
  border-radius:30px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.large-button-purple {
  padding: 0.75rem 1.5rem !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
  background-color: #9C27B0 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0.25rem !important;
  cursor: pointer !important;
  transition: background-color 0.2s !important;
  width:100% !important;
}

.large-button-purple:hover {
  background-color: #7B1FA2 !important;
}

.small-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  color: var(--maincolor);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.small-button:hover {
  color: #0056b3;
}


.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--maincolor);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-top:20px;
  margin-bottom:20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.row{
  width: 100%;
}

.boxTitle{
  font-size: 30px;
  margin-top:0px;
  margin-bottom: 20px;
  color:var(--maincolor);
  font-weight: 100;
  text-align: left;
  width: 100%;
}




.success-icon{
  color:var(--maincolor);
  font-size:60px;
  margin-top:20px;
}

.error-icon{
  color:#ee6363;
  font-size:60px;
  margin-top:20px;
}

.error-text{
  color:#f57474;
  font-size: 16px;
}



body{

 background: -webkit-linear-gradient(to  right , #f1f1f1, #e9f0ff); 
  background: linear-gradient(to  right, #f1f1f1, #e9f0ff);
padding-bottom: 50px;
font-size: 12px;
}

.ripple-background{
  position: fixed;
}
.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 20s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}


.termsLink{
  
  margin-right: 20px;
  font-size: 10px;
  color:var(--maincolor);
  text-decoration: none;
  display: inline-block;
  z-index: 3;
  
  margin-top: 20px;
}


.termsLinkIcon{
  margin-left: 20px;
  font-size: 15px;
  color:var(--maincolor);
  text-decoration: none;
  display: inline-block;
  z-index: 3;
  margin-top: 50px;
}

.balancesText{
  font-size: 16px;
}

.presaleButton{
  position: relative;
  background-color: var(--maincolor);
  border-radius: 10px;
  height:40px;
  width:100%;
  font-size: 17px;
  font-weight: 600;
  top:10px;
  z-index: 5;
  opacity: 1;
  border:1px solid #FFFFFF;
  cursor: pointer;
 margin-top: -80px;
  transition: all 1s;
  text-transform: uppercase;
}

.presaleButton:hover{
  animation-name: none;
  background-color: var(--maincolor);
  opacity: 1;
  font-size: 17px;
  transition: all 1s;
  border:1px solid #FFFFFF;
}


.backBtn{
  background-color: transparent;
  color:#333333;
  border:0px;
  float: left;
}
.backBtn svg{
  margin-right: 3px;
  transition: all 0.5s;
}

.backBtn:hover{
  background-color: transparent;
  color:#333333;
  border:0px;
}
.backBtn:hover svg{
  margin-right: 8px;
  transition: all 0.5s;
}

.clearBtn{
  position: absolute !important;
  left:15px;
  font-size: 15px !important;
  margin-top:-43px;
}

.clearBtn:hover{
  box-shadow:0 4px 24px 0 rgba(0,0,0,0) !important;
}

.clearBtn i{
  font-size: 15px !important;
  
}
.menuRow{
  text-align: left;
}
.backButton{
 
  border:0px;
  padding: 0px;
  top: -3px;
  background-color: transparent;
  color:var(--maincolor);
  font-size:18px;
  font-weight: 600;
  transition: all 0.2s;
}
.smallCircleButtons{
  width:auto;
  border:0px;
  padding: 5px 10px;
  background-color: var(--maincolor);
  color:#ffffff;
  font-size:0.8rem;
  font-weight: 600;
  transition: all 0.2s;
  border-radius: 100px;
  margin-right:15px;
}
.smallCircleButtons i{
  margin-right: 10px;;
}

.loanCard{
  border-radius: 0px 0px 20px 20px;
}

.loanCard .loanBtn{
  width: 100%;
  border: 0px;
  padding: 20px 10px;
  background-color: var(--maincolor);
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 0.2s;
  border-radius: 20px 20px 0px 0px;
  margin-top: 15px;
  margin-bottom: -10px;
  opacity: 0.7;
}

.loanBtnInactive{
  background: #FFFFFF !important;
  color: var(--maincolor) !important;
  font-size: 14px !important;
  opacity: 1 !important;
}

.loanBtn{
  width: 100%;
  border: 0px;
  padding: 20px 10px;
  background-color: var(--maincolor);
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 0.2s;
  border-radius: 50px;
  margin-top: 15px;
  margin-bottom: -10px;
}

.loanBtn i{
  display: inline-block;
  font-size:0.8rem;
  margin-left: 10px;
}

.loanBtn:hover{
  color:#cccccc;
  transition: all 0.2s;
}


.table td{
  text-align: left;
}

.smallText{
  color: #999999;
  font-size: 12px;
}
.smallTitle{
  color: var(--maincolor);
  font-size: 14px;
}
.smallTitleSmall{
  color: var(--maincolor);
  font-size: 12px;
}

.smallestText {
  color: #444;
  font-size: 10px;
  text-align: left;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}

small{
  font-size: 13px;
}

.table>:not(caption)>*>*{
  border:0px;
}

.card{
  text-align: left;
}

.card-img-top{
  width: 100px;
  margin: 0 auto;
  margin-top: -40px;
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 140px;
  
}

.card-img-top-small{
  width: 30px;
  padding: 0px;
  padding-top: 0px;
  margin: 0 auto;

}

.closedSpot{
  color: #FFFFFF;
  font-size: 20px;
  padding: 5px;
}

.openedSpot{
  color: #cccccc;
  font-size: 20px;
  padding: 5px;

}

.lotteryCard{
  background: linear-gradient(to bottom, rgba(255,255,255, 0.9), rgba(255,255,255, 0.7));

  padding: 10px;
  border-radius:10px;
  color:#333333;
  margin-top: 10px;
  box-shadow: 0 4px 24px 0 rgba(0,0,0,.08);
  padding-bottom: 40px;
  margin-top: 30px;
}
.bigText{
  font-size: 35px;
}

.gameSmallText{
  font-size: 15px;
}



.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.btnLottery {
  width: 100%;
  line-height: 20px;
  background: var(--maincolor);
  border-radius: 50px;
  text-align: center;
  margin: 10px;
  font-size: 20px;
  color: #FFFFFF;
  border: none;
  padding: 10px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.btnLottery:hover{
  background: #ffffff;
  color: var(--maincolor);
  cursor: pointer;
}

.btnLottery small{
  font-size: 15px;
  margin-left: 5px;
}

.btnLottery--shockwave.is-active {
  -webkit-animation: shockwaveJump 2s ease-out infinite;
          animation: shockwaveJump 2s ease-out infinite;
}
.btnLottery--shockwave.is-active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  -webkit-animation: shockwave 2s 0.65s ease-out infinite;
          animation: shockwave 2s 0.65s ease-out infinite;
}
.btnLottery--shockwave.is-active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  -webkit-animation: shockwave 1s 0.5s ease-out infinite;
          animation: shockwave 1s 0.5s ease-out infinite;
}

@-webkit-keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
}
@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
}
.btnLottery--jump.is-active {
  -webkit-animation: 0.7s jump ease infinite alternate;
          animation: 0.7s jump ease infinite alternate;
}

@-webkit-keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}

@keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}
.btnLottery--pulse.is-active {
  background: transparent;
}
.btnLottery--pulse.is-active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: pulse 0.4s ease infinite alternate;
          animation: pulse 0.4s ease infinite alternate;
  z-index: -1;
}

.marginRight{
  margin-right: 15px;
  margin-top: 18px;
}

.walletCard{
 
  border-radius: 30px;
  transition: all 0.7s;
}

.walletCardBody{
  padding: 10px;
  position: relative;
}

.walletCardBody .smallSpinner{
  position: absolute;
  left:200px;
  top:10px;
}

.walletIcon{
  margin: 0 auto;
  margin-top: 0px;
  width: 25px;
  margin-right: 15px;
  position: absolute;
  border-radius: 50px;
}

.walletIconSmall{
  margin: 0 auto;
  margin-top: 0px;
  width: 20px;
  margin-right: 5px;
}

.minusLeftMagin{
  margin-left: -15px !important;
}

.plusLeftMagin{
  margin-left: 15px !important;
}

.walletIconStake {
  margin: 0 auto;
  width: 30px;
  margin-right: 0px;
  margin-top: 0px;
}

.buyIframe{
  height: 700px;
  width: 100%;
}

.bigIcon{
  font-size: 40px;
  margin-top:10px;
  margin-bottom:10px;
}

.btnIcon{

  width:auto;
margin-right: 5px;
  display: inline-block;
  padding: 5px 10px;
  margin-top: 5px;
  color:#FFFFFF;
  border-radius: 50px;
  background-color: var(--maincolor);
  border: 1px solid var(--maincolor);
}
.btnIcon:hover{
  color: var(--maincolor);
  border: 1px solid var(--maincolor);
  background-color:#FFFFFF;
}

.btnIcon svg {
  margin-right: 5px;
}

.alert-info{
  font-size: 12px;
  background-color: rgba(141, 184, 252, 0.5);
  color: #333333;
  border: 0px solid var(--maincolor);
}

.card-text-big{
  font-size: 30px;
  margin-top: -10px;
}


.white-container{
  background: rgba(255, 255, 255, 0.7) !important;
}

.white-container-top{
  background: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 0px !important;
}

.container-fix{
  border-radius: 0px !important;
  margin-top: -10px !important;
  padding-top: 5px !important;
  padding-left: 5px !important;
  background: transparent !important;
  box-shadow: none !important;
}


.iframe{
  width: 100%;
  height: 45px;
  max-height: 45px;
  overflow: hidden;
  display: block;
  border-radius: 0px ;
  background-color: rgba(255, 255, 255, 0.6);
}

@media (max-width : 490px) {
  .iframe {
    height: 46px;
    max-height: 46px;
  }
}

.logoStyle{
  width: 70px;
  height: 70px;
  padding: 0px;
  display: block;
  margin: 0 auto;
  margin-top: -15px;
  padding: 10px;

}

@media (max-width : 490px) {
  .logoStyle {
    width: 35px;
    height: 35px;
    margin-top: 5px;
    margin-left: -15px;
    padding: 0px;
  }
}

.networkBox{
  position: relative;
  display: inline-block;
  background-color: #eeeeee;
  border-radius: 20px;
  padding: 4px 10px 4px 25px;
  float: right;
}




.winnerBox{
  font-size: 12px;
  display: block;
  border-radius: 50px;
  background-color:#FFFFFF;
  color: var(--maincolor);
  padding: 15px;

}

.containerMenu{
  display: block;
  text-align: left;
  position: relative;
  width: 100%;
  padding: 10px 0px 30px 0px;
}
.containerMenuBtn i{
  margin-right: 10px;
  color: var(--maincolor)
}
.containerMenuBtn{
  background: linear-gradient(to left,rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.6));
  border-radius: 30px;
  margin-right: 5px;
  border: 0px;
  padding: 5px 10px;
  color: var(--maincolor);
  font-size: 14px;
  transition: all 0.4s;
}

.containerMenuBtn:hover{
  background: linear-gradient(to left,rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.7));
  transition: all 0.4s;
}
.containerMenuBtn.active{
  color: #FFFFFF !important;
  background: var(--maincolor);
}
.containerMenuBtn.active i{
  color: #FFFFFF !important;
}


.newSwapBtn i{
  margin-right: 8px;
}

.newSwapBtn{
  color:#FFFFFF;
  background-color: var(--maincolor);
  display: inline-block;
  border: 0px;
  padding: 8px 20px;
  border-radius: 20px;
  margin-right: 8px;
  
}

.fullScreenConfetti{
  position: fixed !important;
  width: 100%;
}

.copyButton{
  background-color: transparent;
  border: 0px;
  color: #333333;
  font-size: 15px;
  margin-left: 5px;
  line-height: 22px;
  display: inline-block;
  transition: all 1s;
}
.copyButton:hover{
  color: var(--maincolor);
  transition: all 0.2s;
}

.ring-container {
  position: absolute;
  top: -11px;
  left: -15px;
}

.ring-circle {
  width: 10px;
  height: 10px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 23px;
}

.ringring {
  border: 1px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 20px;
  top: 20px;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite; 
  opacity: 0.0
}
@keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

.hideOnMobile{
  display: inline-block;
  margin-top: 7px;
}
.showOnMobile{
  display: none !important;
  margin-top: 7px;
}


@media (max-width : 490px) {
  .hideOnMobile{
    display: none !important;
  }
  .showOnMobile{
    display: flex !important;
    
  }
  .showOnMobile div{
    line-height: 20px !important;
  }
  .showOnMobile i{
    margin-right: 10px !important;
  }
}

.loteryPositionIcon{
  padding: 20px;
  border-radius: 40px;
  margin: 5px;
  font-size: 20px;
  color: var(--maincolor);
  background-color: #FFFFFF;
  border: 3px solid var(--maincolor);
}

.loteryPositionIconFilled{
  padding: 20px;
  border-radius: 40px;
  margin: 5px;
  font-size: 20px;
  background-color: var(--maincolor);
  color: #FFFFFF;
  border: 3px solid var(--maincolor);
}

.gameResultsDiv{
  background-color: rgba(255,255,255,0.1);
  color: #FFFFFF;
  margin-top: 0px;
  padding: 10px 0px;
  font-size: 10px !important;
  border-bottom: 1px solid #e1e1e1;
}


.smallBalanceContainer{
  font-size: 12px;
  line-height: 20px;
}

.smallBalanceContainer small{
  font-size: 10px;
}

.smallTokenIcon{
  width:15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -3px;
}


.transparentBackground{
  background: transparent !important;
  box-shadow: none !important;
  padding-left: 10px !important;
  padding-right: 0px !important;
}

.whiteText{
  color:#FFFFFF !important;
}

.buyButton{
  font-size: 12px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--maincolor);
  border: 0px;
  padding: 3px 8px;
  border-radius: 30px;
  margin-top: 0px;
  transition: all 0.7s;
  margin-right: 5px;
}

.buyButton:hover{
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.mediumButton{
  font-size: 14px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--maincolor);
  border: 0px;
  padding: 8px 25px;
  border-radius: 30px;
  margin-top: -10px !important;
  transition: all 0.7s;
}

.mediumButton:hover{
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.btnReset{
  font-size: 20px;
  text-align: center;
  background-color: var(--maincolor);
  color: #ffffff;
  border: 0px;
  padding: 17px 22px;
  border-radius: 40px;
  bottom: 20px;
  right: 20px;
  transition: all 0.7s;
  position: fixed;
  right: 15px;

  z-index: 10;
}

.btnReset:hover{
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.infoButton{
  font-size:28px;
  text-align: center;
  color: var(--maincolor);
  background-color: transparent;
  border: 0px;
  border-radius: 50px;
  margin-top: 0px;
  transition: all 0.7s;
  position: absolute;
  right: 7px;
  margin-top: -42px;
  z-index: 10;
}



.infoButton:hover{
  
  transition: all 0.2s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.05);
}

.tooltipButton{
  font-size: 14px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--maincolor);
  border: 0px;
  padding: 3px 6px;
  border-radius: 30px;
  margin-top: 0px;
  transition: all 0.7s;

  right: 15px;
  margin-top:-50px;
  z-index: 10;
}

.tooltipButton:hover{
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s;
  color: var(--maincolor);
  box-shadow:0 4px 24px 0 rgba(0,0,0,.1);
}

.rewardsBox{
  background-color:  var(--maincolor);
  color: #FFFFFF;
  padding: 10px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}



.borderRadius100{
  border-radius: 100px !important;
}


.rewardsBox h5{
  display: block;
  font-size: 14px;
  margin-bottom: 0px;
}

.rewardsBox h4{
  display: block;
  font-size: 20px;
  line-height: 30px;
}

.rewardsBox .walletIcon{
  margin-top: 10px;
}
.stakeholdersPool{
  
  
  padding-left: 20px;
  padding-bottom: 20px;
  min-width: 80%;
}
.waweSwap{
  
  
 margin-top: -10px;
  padding-bottom: 20px;
  min-width: 100%;
}

.whiteCard{
  background-color: #FFFFFF;
  border-radius: 30px;
  padding: 20px 10px;
  margin: 0px 0px;
}

.stakeholders .card-text{
  font-size: 15px;
}

.stakeholders .smallText{
  font-size: 12px;
}


.win{
  font-size: 10px;
  text-align: left;
  display: block;
  color: #66BB6A;
}
.win a{
  color: #333333;
  text-decoration: none;
}
.lose{
  font-size: 10px;
  text-align: left;
  display: block;
  color: #FF7043;
}
.lose a{
  color: #333333;
  text-decoration: none;
}

.width100{
  width: 100%;
}

.row>* {
  padding-right: 0px;
}

.gameNumber{
  font-size: 10px;
  color: var(--maincolor) !important;
  text-align: center;
}

.gameNumber .icon {
  display: block;
  font-size: 12px;
  color: var(--maincolor) !important;
  margin: 0 auto;
  margin-top: 2px;
  margin-bottom: 2px;
}


.giftIcon{
  width:25px;
  position: absolute;
  top: 0px;
  margin-left: 20px;
  rotate: 15deg;
  background-color: white;
  padding: 3px;
  border-radius: 20px;
}

@media (max-width : 490px) {
  .gameResultsDiv{
    font-size: 8px !important;
  }
  .lose{
    font-size: 8px !important;
  }
  .win{
    font-size: 8px !important;
  }

  .gameNumber{
    font-size: 8px;
  }
  
  .gameNumber .icon {
    font-size: 10px;
  }

.giftIcon{
  width:15px;
  margin-left: 15px;
  margin-top: 5px;
  padding: 1px;
}
}


.snowflake {
  --size: 0.1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  z-index: 0;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}
.snowflake:nth-child(1) {
  --size: 0.8vw;
  --left-ini: 6vw;
  --left-end: 9vw;
  left: 78vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(2) {
  --size: 0.1vw;
  --left-ini: -8vw;
  --left-end: -5vw;
  left: 76vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(3) {
  --size: 0.2vw;
  --left-ini: 6vw;
  --left-end: 8vw;
  left: 79vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(4) {
  --size: 0.1vw;
  --left-ini: -3vw;
  --left-end: 1vw;
  left: 60vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(5) {
  --size: 0.4vw;
  --left-ini: -4vw;
  --left-end: 1vw;
  left: 85vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(6) {
  --size: 0.06vw;
  --left-ini: 0vw;
  --left-end: -6vw;
  left: 62vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(7) {
  --size: 0.1vw;
  --left-ini: 2vw;
  --left-end: -1vw;
  left: 25vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(8) {
  --size: 0.4vw;
  --left-ini: 5vw;
  --left-end: 9vw;
  left: 7vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(9) {
  --size: 0.1vw;
  --left-ini: -2vw;
  --left-end: -4vw;
  left: 27vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(10) {
  --size: 0.1vw;
  --left-ini: 2vw;
  --left-end: 0vw;
  left: 5vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(11) {
  --size: 0.1vw;
  --left-ini: 3vw;
  --left-end: 8vw;
  left: 57vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(12) {
  --size: 0.1vw;
  --left-ini: 9vw;
  --left-end: 6vw;
  left: 63vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(13) {
  --size: 0.1vw;
  --left-ini: 1vw;
  --left-end: 7vw;
  left: 30vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(14) {
  --size: 0.1vw;
  --left-ini: 8vw;
  --left-end: 7vw;
  left: 21vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(15) {
  --size: 0.1vw;
  --left-ini: 1vw;
  --left-end: 4vw;
  left: 5vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(16) {
  --size: 0.2vw;
  --left-ini: 3vw;
  --left-end: -4vw;
  left: 99vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(17) {
  --size: 0.4vw;
  --left-ini: 9vw;
  --left-end: 2vw;
  left: 58vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(18) {
  --size: 0.06vw;
  --left-ini: -9vw;
  --left-end: 0vw;
  left: 23vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(19) {
  --size: 0.1vw;
  --left-ini: 9vw;
  --left-end: -1vw;
  left: 50vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(20) {
  --size: 0.01vw;
  --left-ini: -9vw;
  --left-end: -9vw;
  left: 20vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(21) {
  --size: 0.1vw;
  --left-ini: -2vw;
  --left-end: -3vw;
  left: 90vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(22) {
  --size: 0.1vw;
  --left-ini: -3vw;
  --left-end: -8vw;
  left: 60vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(23) {
  --size: 0.1vw;
  --left-ini: 3vw;
  --left-end: -5vw;
  left: 70vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(24) {
  --size: 0.6vw;
  --left-ini: 2vw;
  --left-end: -2vw;
  left: 66vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(25) {
  --size: 0.04vw;
  --left-ini: 4vw;
  --left-end: 1vw;
  left: 38vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(26) {
  --size: 0.1vw;
  --left-ini: -6vw;
  --left-end: 2vw;
  left: 99vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(27) {
  --size: 0.2vw;
  --left-ini: 4vw;
  --left-end: -5vw;
  left: 6vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(28) {
  --size: 0.1vw;
  --left-ini: 4vw;
  --left-end: 8vw;
  left: 64vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(29) {
  --size: 0.8vw;
  --left-ini: 2vw;
  --left-end: 2vw;
  left: 34vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(30) {
  --size: 0.01vw;
  --left-ini: 6vw;
  --left-end: -1vw;
  left: 71vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(31) {
  --size: 0.06vw;
  --left-ini: -9vw;
  --left-end: 5vw;
  left: 94vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(32) {
  --size: 0.2vw;
  --left-ini: 7vw;
  --left-end: -2vw;
  left: 70vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(33) {
  --size: 0.1vw;
  --left-ini: 8vw;
  --left-end: -1vw;
  left: 24vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(34) {
  --size: 0.1vw;
  --left-ini: -7vw;
  --left-end: -3vw;
  left: 9vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -10s;
}

.snowflake:nth-child(35) {
  --size: 0.2vw;
  --left-ini: -1vw;
  --left-end: 2vw;
  left: 89vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(36) {
  --size: 0.2vw;
  --left-ini: -3vw;
  --left-end: 10vw;
  left: 67vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(37) {
  --size: 0.1vw;
  --left-ini: -8vw;
  --left-end: 1vw;
  left: 74vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(38) {
  --size: 0.4vw;
  --left-ini: -8vw;
  --left-end: 6vw;
  left: 33vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(39) {
  --size: 0.1vw;
  --left-ini: 7vw;
  --left-end: 10vw;
  left: 54vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -5s;
}

.snowflake:nth-child(40) {
  --size: 0.06vw;
  --left-ini: 4vw;
  --left-end: -7vw;
  left: 36vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -1s;
}

.snowflake:nth-child(41) {
  --size: 0.2vw;
  --left-ini: 0vw;
  --left-end: 3vw;
  left: 76vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(42) {
  --size: 0.06vw;
  --left-ini: 1vw;
  --left-end: -2vw;
  left: 91vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
}

.snowflake:nth-child(43) {
  --size: 0.2vw;
  --left-ini: 7vw;
  --left-end: -6vw;
  left: 26vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(44) {
  --size: 0.06vw;
  --left-ini: -4vw;
  --left-end: 0vw;
  left: 88vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -8s;
}

.snowflake:nth-child(45) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: 0vw;
  left: 53vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -9s;
}

.snowflake:nth-child(46) {
  --size: 0.06vw;
  --left-ini: -5vw;
  --left-end: 6vw;
  left: 72vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -3s;
}

.snowflake:nth-child(47) {
  --size: 0.02vw;
  --left-ini: 9vw;
  --left-end: -7vw;
  left: 71vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(48) {
  --size: 0.01vw;
  --left-ini: -3vw;
  --left-end: 1vw;
  left: 22vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -2s;
}

.snowflake:nth-child(49) {
  --size: 0.06vw;
  --left-ini: 6vw;
  --left-end: -5vw;
  left: 49vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
}

.snowflake:nth-child(50) {
  --size: 0.06vw;
  --left-ini: -3vw;
  --left-end: 3vw;
  left: 75vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -10s;
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px);
}

.halvingBalancesContainer{
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
}

.halvingBalancesRow{
  
  width: 100%;
  overflow:auto;
}

.table{
  margin-bottom: 0px;
}

.table td{

  background: transparent;
}

.table th{
  background: transparent;
}
.balancesTitle{
  font-size: 8px;
}

.table .rewardsBox{
  min-width: 200px;
  display: inline-block;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
  border-radius: 5px;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7));
  color: var(--maincolor);
  position: relative;
  padding: 0px;

}
.table .halvingStep{
  font-weight: 700;
  font-size: 20px;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 45px;
  height: 100%;
  border-radius: 50px 0px 0px 50px;
  color: #FFFFFF;
  text-align: center;

}


.table .rewardsBox h6{
  color: var(--maincolor);
  font-size: 10px;
  display: inline-block;
}

.movingDisplay .rewardsBox{
  min-width: 200px;
  display: inline-block;
  margin-bottom: 0px;
  margin-top: 0px;
 
  border-radius: 0px;
  background: transparent;
  color: var(--maincolor);
  position: relative;
  padding: 0px;
  padding-right: 10px;
  
  margin-right: 20px;

}
.movingDisplay .halvingStep{
  font-weight: 700;
  font-size: 20px;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 45px;
  height: 100%;
  border-radius: 50px 0px 0px 50px;
  color: #FFFFFF;
  text-align: center;
}


.movingDisplay .rewardsBox h6{
  color: #555555;
  font-size: 10px;
  display: inline-block;
}

.movingDisplay .rewardsBox .smallText{
  color: #777777;
  font-size: 8px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.width70{
  width: 70% !important;
}

.borderBlue{
  border:2px solid var(--maincolor) !important;
  box-shadow:0 4px 24px 0 rgba(0,0,0,.01);
  border-radius: 30px !important;
}

.borderBlue1{
  box-shadow:0 4px 24px 0 rgba(0,0,0,.01);
  border-radius: 30px !important;
}

.marginLeft6{
  margin-left:-6px !important;
}

input:disabled{
  background-color: #f1f1f1;
}

.modal-title {
  font-size: 1.5rem; /* Larger font size for modal title */
  padding-left: 5px;
}

.form-label {
  font-weight: 500; /* Make labels bold */
  font-size: 12px;
  margin-bottom: 0px;
  color: #777777;
}

.btn-primary {
  background-color: var(--maincolor); /* Bootstrap primary color */
  border-color: var(--maincolor);
  border-radius: 30px;
  min-width: 50px;
}

/* Additional styles for disabled inputs to make them visually distinct */
.form-control:disabled {
  background-color: #e9ecef;
  color: #6c757d;
  padding: 4px !important;
}

/* Style for the swap button */
.btn-primary {
  padding: 10px; /* Increase padding for a larger button */
  font-size: 1.1rem; /* Larger font size for button text */
}
/* Style for the swap button */
#list .btn-primary {
  padding: 5px; /* Increase padding for a larger button */
  font-size: 14px; /* Larger font size for button text */
  background-color: transparent;
  color: var(--maincolor);
}
#list .btn-primary:disabled {
  background-color: transparent;
  color: #a2a2a2;
  border: 1px solid #a2a2a2;
}

#list .form-select{
  border-radius: 30px;
  font-size: 12px;
}

#list .table td{
  padding-bottom: 8px;
}

/* Spacing for form elements */
.mb-3 {
  margin-bottom: 1rem; /* Bootstrap spacing for margin-bottom */
}










.card,
.large-button-blue {
 
}

.btn-outline-primary{
  --bs-btn-color: var(--maincolor);
  --bs-btn-border-color: var(--maincolor);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--maincolor);
  --bs-btn-hover-border-color: var(--maincolor);
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--maincolor);
  --bs-btn-active-border-color: var(--maincolor);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--maincolor);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--maincolor);
  --bs-gradient: none;
  border-radius: 25px;
  padding: 8px 20px !important;
}



h4 {
  color: #007bff; /* Primary color for the title */
  margin-bottom: 1rem;
}


/* Strong tag styling for labels */
strong {
  color: #444;
  font-weight: 500; /* Making the labels bold */
}


.modal-content{
  background: #ffffff;
  border-radius: 15px;
}

.modal-header{
  display: block;
  color: var(--maincolor);
}
.modal-header,
.modal-footer{
  border:0px !important;
}

.btn-close{
  position: absolute;
  top:27px;
  right: 30px;
}


.bridgeBox{
  color:  var(--maincolor);
  background-color: rgba(255, 255, 255, 0.8);;
  padding: 10px 10px;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
  position: relative;
}
.bridgeBox h6{
  display: block;
  font-size: 14px;
  margin-bottom: 0px;
}

.bridgeBox h4{
  display: block;
  font-size: 15px;
  line-height: 30px;
}

.bridgeBox .walletIcon{
  width: 20px;
  height: 20px;
  margin-top: 12px;
 
  position: relative;
}

.bridgeBox i{
  display: block;
  margin-top: 9px;
}

.networkIcon{
  width: 25px;
  height: 25px;
  transition: all 0.3s;
  margin-right: 5px;
}
.networkIcon:hover{
  width: 22px;
  height: 22px;
  transition: all 0.3s;
}

.btn-network{
  background-color: transparent;
  border: 0px;

}

.btn-network:hover{
  background-color: transparent;
  border: 0px;
}

.iconPrice{
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.swapProgress {
  height: 8px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: #dedede;
}
.swapProgress .bg-info{
  background-color: var(--maincolor) !important;
}


.dropdownStyle{
  padding: 10px; 
  border:0px;
  border-radius: 30px;
  margin:0 auto;
  appearance: none;
  color: var(--maincolor);
  background-color:#FFFFFF;
  width: 100%;
  font-weight:600;
  font-size:13px;
  margin-top: 0.25rem;
  cursor:pointer;
  box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  text-align-last: center; 
  text-align: center;
}

.dropdownMenuStyle{
  width: 100%;
  border: 0px;
  box-shadow:rgba(99, 99, 99, 0.3) 0px 2px 8px 0px !important;
  border-radius: 20px;
}

.dropdownStyle:hover{
  background-color:var(--maincolor);
}
.dropdownStyle:disabled{
  background-color:#FFFFFF;
  color: var(--maincolor);
  opacity: 1;
}
.dropdownStyle:disabled.dropdown-toggle::after{
  color:#FFFFFF !important;
}

.numCircle{
  display: block;
  color: var(--maincolor);
  border: 1px solid var(--maincolor);
  font-size: 15px;
  height: 34px;
  width: 34px;
  border-radius: 20px;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 7px;
  font-weight: bold;
}

.smallIconGray{
  display: block;
  margin-top: 0px;
  font-size: 16px;
  color: #999;
}

.bridgeBtn{
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.4s;
  color: #999999;
}
.bridgeBtn:hover{
  transition: all 0.4s;
  color: var(--maincolor);
}

.formBox{
  text-align: left;
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.3));
  background: #f9f9f9;
  padding: 10px;
  border-radius: 15px;
}

.formBox small{
  font-weight: 400 !important;
}
.formAdminBlock{
  display: inline-block;
  padding: 5px;
  width: 200px;
}
.form-control-small{
  display: block;
  padding: 5px !important;
}

.formBox .form-control{
  border: 0px;
  background-color: transparent;
  box-shadow: none !important;
  text-align: left;
  text-align-last: left;
  font-size: 23px;
  padding-top: 5px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  color: #555555;
}


th{
  text-align: left;
}

.desktopMenu div{
 font-size: 14px !important;
 
}

.desktopMenu i{
 margin-right: 10px !important;
 margin-left: 0px !important;
 font-size: 18px !important;
}


.bigLoader{
  color: var(--maincolor) !important;
  height: 80px;
  width: 80px;
  margin-top: 80px;
  margin-bottom: 30px;
}

.accordionClosed{
  background: none;
  border: none;
  float: right;
  color: var(--maincolor);
}

.accordionActive{
  background: none;
  border: none;
  float: right;
  color: #999999;
}

.activeBtn{
  color: var(--maincolor) !important;
  font-weight: 500 !important;
  
}
.activeBtn i{
  color: var(--maincolor) !important;
  font-size: 18px !important;
  
}


.formBox .form-control::placeholder {
  color: #bbbbbb;
  opacity: 1; /* Firefox */
}

.formBox .form-control::-ms-input-placeholder { /* Edge 12 -18 */
  color: #bbbbbb;
}

.toastMessage {
  position: fixed;
  right: 20px;
  z-index: 100;
  bottom: 20px;
  background-color: #66BB6A;
  border: 0px;
  text-align: left;
  color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 30px;
  font-size: 15px;
}

.toastMessageError{
  background-color: #b84747;
}

.link{
  text-decoration: underline;
  cursor: pointer;
}

.bridgeInfo .infoButton{
  font-size: 14px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--maincolor);
  border: 0px;
  padding: 6px 10px;
  border-radius: 30px;
  margin-top: 0px;
  transition: all 0.7s;
  position: absolute;
  right: 15px;
  margin-top:-45px;
  z-index: 10;
}

.text-left{
  text-align: left !important;
}